<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        任务<Input clearable placeholder="输入任务 搜索" class="search-input"
                 v-model="queryinfo.taskname"
                 style="width:150px"
                 @on-enter="namechange"
                 @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        用户名<Input clearable placeholder="输入用户名 搜索" class="search-input"
                  v-model="queryinfo.created_user"
                  style="width:150px"
                  @on-enter="namechange"
                  @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        任务分类<Input clearable placeholder="输入任务分类 搜索" class="search-input"
                   v-model="queryinfo.customtype_name"
                   style="width:150px"
                   @on-enter="namechange"
                   @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        任务类型
        <Select v-model="queryinfo.tasktype" style="width:150px" clearable  @on-change="namechange">
          <Option v-for="item in tasktypelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        模块名称
        <Select v-model="queryinfo.modular" style="width:150px" clearable  @on-change="namechange">
          <Option v-for="item in modulelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        状态
        <Select v-model="queryinfo.state" style="width:150px" clearable  @on-change="namechange">
          <Option v-for="item in statelist" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
    </Card>

    <template>
      <Page :current="page"
            :total="total"
            :page-size-opts="pagesizeopts"
            @on-change="changepage"
            @on-page-size-change="changepagesize"
            show-total
            show-sizer
            style="text-align: right;" />
    </template>
    <Drawer
        :closable="false"
        v-model="showupdatatask"
        title="查看配置详情"
        width="700">
      <p>任务名称：{{updatetaskform.taskname}}</p>
      <p>任务类型：{{tasktypedict[updatetaskform.tasktype]}}</p>

      <p>任务模块：{{updatetaskform.modular}}</p>
      <template v-if="updatetaskform.modular === 'Playbook'">
        <p>剧本使用：{{updatetaskform.playbook_name}}</p>
      </template>

      <p>任务参数：{{updatetaskform.args}}</p>
      <p>任务分类: {{updatetaskform.customtype_name}}</p>
      <template v-if="updatetaskform.customtype_name === '切任务'">
        <p>source: {{updatetaskform.source}}</p>
        <p>target: {{updatetaskform.target}}</p>
      </template>
      <p>任务机器：{{updatetaskform.hosts}}</p>
      <p>任务步长：{{updatetaskform.stepsize}}</p>
    </Drawer>
  </div>
</template>

<script>
import {get,post,put,del} from "@/api/http.js";
import moment from "moment";
export default {
  name: "index",
  data () {
    return {
      data: [],
      loadingplaybook: false,
      loadingtype: false,
      page: 1,
      pagesizeopts: [10, 20, 30, 40],
      pagesize: 10,
      total: 0,
      showdelete: false,
      showstop: false,
      options2: [],
      streelist: [],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '任务名称',
          key: 'taskname',
          width: 200,
        },
        {
          title: '创建用户',
          key: 'created_user',
          align: 'center',
          width: 120,
        },
        {
          title: '任务类型',
          key: 'tasktype',
          width: 100,
          render: (h,{row}) =>{
            let tasktypeColor = {
              "1": ['模块', '#2db7f5'],
              "2": ['剧本', '#19be6b']
            }
            return h("Badge",{
              props: {
                color: tasktypeColor[row.tasktype][1],
                text:  tasktypeColor[row.tasktype][0]
              }
            })
          }
        },
        {
          title: '模块名称',
          key: 'modular',
          align: 'center',
          width: 120,
        },
        {
          title: '任务分类',
          key: 'customtype_name'
        },
        {
          title: '剧本名',
          key: 'playbook_name',
          align: 'center',
          width: 120,
        },
        {
          title: '参数',
          key: 'args',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '创建时间',
          key: 'created_at',
          ellipsis: true,
          width: 200,
          render: (h,{row}) =>{
            return h('div',this.time_change(row.created_at))
          }
        },
        {
          title: '状态',
          key: 'state',
          width: 100,
          render: (h,{row}) =>{
            let stateColor = {
              "0": ['失败', '#ed4014'],
              "1": ['成功', '#19be6b'],
              "2": ['警告', '#ff9900'],
              "3": ['异常', '#ed4014'],
              "4": ['已终止', '#ff9900'],
              "8": ['等待中', '#5cadff'],
              "9": ['进行中', '#2db7f5'],
              "10": ['已停止', '#19be6b']
            }
            return h("Badge",{
              props: {
                color: stateColor[row.state][1],
                text:  stateColor[row.state][0]
              }
            })
          }
        },
        {
          title: '操 作',
          key: 'action',
          width: 120,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showdetails(params.row)
                  }
                }
              }, ' 详情 |'),
              h('a', {
                on: {
                  click: () => {
                    this.showupdata(params.row)
                  }
                }
              }, ' 参数 '),
            ]);
          }
        }
      ],
      showaddtask: false,
      showupdatatask: false,
      tasktypelist: [
        {value: 1, label: '模 块'},
        {value: 2, label: 'Playbook'}
      ],
      tasktypedict: {
        "1": "模 块",
        "2": "Playbook",
      },
      playbooklist: [],

      args:{
        shell: 'uptime',
        yum: "name=httpd state=present",
        copy: "src=/root/my.txt dest=/tmp/test.txt",
        file: "path=/tmp/my.cn mode=600 state=touch",
        cron: "name='sync time' minute=*/3 hour=* day=* month=* weekday=* job='/usr/sbin/ntpdate window.time.com'",
        wget: "url=https://my.com/file.tar.gz dest=/tmp",
        service: "name=httpd state=restarted enabled=yes"
      },
      customtypelist: [],
      statelist: [
        {value: "0",label:"失败"},
        {value: "1",label:"成功"},
        {value: "2",label:"警告"},
        {value: "3",label:"异常"},
        {value: "4",label:"已终止"},
        {value: "8",label:"等待中"},
        {value: "9",label:"进行中"},
        {value: "10",label:"已停止"},
      ],
      modulelist: [
        {
          value: 'ping',
          label: 'ping'
        },
        {
          value: 'shell',
          label: 'shell'
        },
        {
          value: 'yum',
          label: 'yum'
        },
        {
          value: 'script',
          label: 'script'
        },

      ],
      deltasklog: {},
      addtaskform: {},
      updatetaskform: {},
      queryname:{
        name:"",
      },
      queryplaybookinfo: {
        page: 1,
        pagesize: 10,
        name:"",
      },
      queryinfo: {
        page: 1,
        pagesize: 10,
        taskname:"",
        created_user:"",
        customtype_name:"",
      },
    }
  },
  methods: {
    time_change(time_str) {
      console.log(time_str)
      return moment(time_str).format('YYYY-MM-DD HH:mm:ss')
    },

    selectplaybook (query) {
      if (query !== '') {
        this.loadingplaybook = true;
        this.queryplaybookinfo.name = query.trim()
        this.getPlaybooklist(this.queryplaybookinfo)
      }
    },

    selectcustomtype (query) {
      if (query !== '') {
        this.loadingtype = true;
        this.queryname.name = query.trim()
        this.getcustomtypelist(this.queryname)
      }
    },
    get_stree() {
      const url = "/stree/stree/path/simple_list"
      get(url, "")
          .then((resp)=>{
            if (resp["code"] === 0) {
              this.streelist = resp["data"]
            } else {
              this.$Message.error("服务树请求失败")
            }
          }).catch(e=>{
        return e
      })
    },
    namechange() {
      this.queryinfo.taskname = this.queryinfo.taskname.trim()
      this.queryinfo.created_user = this.queryinfo.created_user.trim()
      this.queryinfo.customtype_name = this.queryinfo.customtype_name.trim()
      this.queryinfo.page = 1
      this.getdata(this.queryinfo)
    },

    getdata(queryinfo) {
      const url = "/goansible/api/v1/task/audit"
      get(url,queryinfo)
          .then((resp)=>{
            this.data = resp["data"];
            this.total = resp.count
          }).catch(e=>{
        return e
      })
    },
    showdetails(row) {
      if (row.state === 8) {
        this.$Message.warning('任务还在等待中');
      } else {
        const api = '/opsTools/AnsibleTools/tasklog/history/' + row.id
        this.$router.push(api);
      }
    },
    canceldelete () {},
    showupdata(row) {
      this.getPlaybooklist(this.queryplaybookinfo)
      this.showupdatatask = true
      this.updatetaskform = row
    },
    selectqueryinfo () {
      this.gettaskinfo(this.page,this.pagesize)
    },
    isdelete (row) {
      this.deltasklog = row
      this.showdelete = true
    },

    isstop (row) {
      this.deltasklog = row
      this.showstop = true
    },
    changepage (page) {
      this.queryinfo.page = page
      this.getdata(this.queryinfo)
    },

    changepagesize(pagesize) {
      this.queryinfo.pagesize = pagesize
      this.getdata(this.queryinfo)
    },

    resettask(row) {
      get(`/goansible/api/v1/task/reset?id=`+ row.id)
          .then((resp)=>{
            this.$Message['info']({background: true,content: resp.msg})
          }).catch(e=>{
        return e
      })
    },

    addtaskvalidate () {
      this.addtask(this.addtaskform)
      this.showaddtask = false
    },

    deletetasklog(id) {
      del(`/goansible/api/v1/task/submit?id=`+ id)
          .then((resp)=>{
            this.queryinfo.page = 1
            this.getdata(this.queryinfo)
          }).catch(e=>{
        return e
      })
    },

    stoptask(id) {
      get(`/goansible/api/v1/task/stop?id=`+ id)
          .then((resp)=>{
            this.gettaskinfo()
          }).catch(e=>{
        return e
      })
    },

    updatetaskvalidate () {
      this.updatetask(this.updatetaskform.id,this.updatetaskform)
      this.showupdatatask = false
    },

    cancel () {
      this.showupdatatask = false
    },

    handleReset (name) {
      this.$refs[name].resetFields();
    },
    canceladd () {
      this.showaddtask = false
    },
    changemodule () {
      this.addtaskform.args = this.args[this.addtaskform.modular]
    },

    changeupdatemodule () {
      this.updatetaskform.args = this.args[this.updatetaskform.modular]
    },

    changetasktype_to_args () {
      if (this.addtaskform.tasktype === 2) {
        this.addtaskform.args = ""
        this.getPlaybooklist(this.queryplaybookinfo)
      } else (
          this.changemodule()
      )
    },
    getPlaybooklist(queryplaybookinfo) {
      get("/goansible/api/v1/ansible/playbook", queryplaybookinfo)
          .then((resp)=>{
            this.playbooklist = resp.data
            this.loadingplaybook = false
          }).catch(e=>{
        return e
      })
    },

    getcustomtypelist(queryname) {
      const url = "/goansible/api/v1/ansible/type"
      get(url, queryname)
          .then((resp)=>{
            this.customtypelist = resp.data
            this.loadingtype = false
          }).catch(e=>{
        return e
      })
    },

  },
  created() {
    this.getdata(this.queryinfo)
  }
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 150px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
